import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from './index.module.scss';
import { Divider, Button } from 'antd';
import { getQuota } from '../../store/action/global';
import { HomeOutlined } from '@ant-design/icons';

export const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const quotaData = useSelector((state) => state.global.quota);
  const userId = useSelector((state) => state.global.userId);
  const showLogin = useSelector((state) => state.global.showLogin);

  useEffect(() => {
    if (!showLogin) {
      getQuota();
    }
  }, [showLogin]);

  const jumpPage = (url) => {
    history.push(url);
  };

  const logout = () => {
    dispatch({
      type: 'global/logout',
    });
  };

  const loginBtn = () => {
    dispatch({
      type: 'global/setShowLogin',
      payload: true,
    });
  };

  return (
    <header>
      <div className={`${styles['container']} ${styles['header-content']}`}>
        <div>
          <a href="https://www.breezedeus.com" className={styles['logo']}>
            <HomeOutlined className={styles['logoImg']} />
            <span className={styles['logo-text']}>Pix2Text</span>
            <span className={styles['beta-tag']}>beta</span>
          </a>

          <div className={styles.logoInfo}>A Free Alternative to Mathpix</div>
        </div>

        <nav>
          <ul>
            <li>
              <div onClick={() => jumpPage('/')}>Home</div>
            </li>
            <li>
              <a href="https://www.breezedeus.com/article/pix2text">Models</a>
            </li>
            <li>
              <div onClick={() => jumpPage('/pricing')}>Pricing</div>
            </li>
          </ul>
        </nav>

        {userId !== null && (
          <div className={styles.login}>
            <div className={styles.userInfo}>
              <div className={styles.quota}>
                <div className={styles.quotaTitle}>Quota</div>
                <div className={styles.quotaContent}>
                  <div>
                    <span>Plus: </span>
                    <div>{quotaData.plus_quota}</div>
                    <div>Expires on {dayjs(quotaData.plus_expiry_date).format('YYYY-MM-DD')}</div>
                  </div>
                  <div>
                    <span>Pro: </span>
                    <div>{quotaData.pro_quota}</div>
                    <div>Expires on {dayjs(quotaData.pro_expiry_date).format('YYYY-MM-DD')}</div>
                  </div>
                </div>
              </div>
              <Divider style={{ margin: '10px 0', borderColor: '#d9d9d9' }} />
              <div onClick={() => history.push('/pricing')} className={styles.updatePassword}>
                <Button type="primary" ghost block>
                  Recharge Plus
                </Button>
              </div>
              <div
                onClick={() =>
                  dispatch({
                    type: 'global/setShowUpdatePassword',
                    payload: true,
                  })
                }
                className={styles.updatePassword}
              >
                <Button block>Update Password</Button>
              </div>
              <div onClick={() => logout()} className={styles.logout}>
                <Button block>Log out</Button>
              </div>
            </div>
            <div className={styles.user}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={styles['user-icon']}
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </div>
          </div>
        )}
        {userId === null && (
          <div className={styles.loginBtn} onClick={loginBtn}>
            <Button ghost>Log in</Button>
          </div>
        )}
      </div>
    </header>
  );
};
