import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ImagesQuicklyCompress from 'images-quickly-compress';
import { Upload, Spin, Select, Modal, Tag } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { imgOcr, imgOcrGpu, getTaskResult, getTaskResultGpu } from '../../../../services/index';
import styles from './index.module.scss';
import { fileTypeItems, languages, models } from '../../config';

const { Dragger } = Upload;

export const OcrInput = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const quotaData = useSelector((state) => state.global.quota);
  const model = useSelector((state) => state.global.model);
  const language = useSelector((state) => state.global.language);
  const fileType = useSelector((state) => state.global.fileType);

  const [orcLoading, setOrcLoading] = useState(false);
  const [img, setImg] = useState('');

  useEffect(() => {
    const historyValue = localStorage.getItem('historyValue');
    if (historyValue) {
      inputChange({ target: { value: historyValue } });
    }
  }, []);

  useEffect(() => {
    function paste(e) {
      const clipdata = e.clipboardData || window.clipboardData;
      const files = clipdata.files;
      const imgFiles = [];
      if (files.length) {
        for (var i = 0; i < files.length; i++) {
          const IMAGE_MIME_REGEX = /^image\/(p?jpeg|gif|png)$/i;
          if (IMAGE_MIME_REGEX.test(files[i].type)) {
            imgFiles.push(files[i]);
          }
        }
      }
      if (imgFiles.length) {
        fileChange({ file: imgFiles[0] });
        e.preventDefault();
      }
    }
    window.addEventListener('paste', paste);
    return () => {
      window.removeEventListener('paste', paste);
    };
  }, [model, language, fileType]);

  const fileChange = (e) => {
    const sessionId = localStorage.getItem('session_id') || '';
    if (sessionId === '') {
      return showLoginPopup(true);
    }
    uploadFunc(e.file);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(e.file);
    fileReader.onload = function () {
      setImg(this.result);
    };
  };

  const imgQuality = (file) => {
    const imageCompress = new ImagesQuicklyCompress({
      size: '50kb',
      imageType: file.type,
      quality: 0.8,
      orientation: true,
    });
    return imageCompress.compressor([file]);
  };

  const uploadFunc = async (file) => {
    try {
      const sessionId = localStorage.getItem('session_id') || '';
      if (sessionId === '') {
        return showLoginPopup(true);
      }
      setOrcLoading(true);
      const newFile = await imgQuality(file);
      const formData = new FormData();
      formData.append('image', newFile[0]);
      formData.append('session_id', sessionId);
      formData.append('language', language);
      formData.append('file_type', fileType);
      formData.append('resized_shape', 768);
      const fun = model === 'plus' ? imgOcrGpu : imgOcr;
      const result = await fun(formData);
      if (result.status !== 200) throw result;
      const { status_code, call_id, task_id } = result.data;
      if (status_code === 200) {
        getResult(task_id, model);
        dispatch({
          type: 'global/setTaskId',
          payload: task_id,
        });
        dispatch({
          type: 'global/setCallId',
          payload: call_id,
        });
      } else {
        throw result;
      }
    } catch (error) {
      setOrcLoading(false);
    }
  };

  const getResult = async (taskId, modelType) => {
    try {
      const fun = modelType === 'pro' ? getTaskResult : getTaskResultGpu;
      const result = await fun({ task_id: taskId, session_id: localStorage.getItem('session_id') });
      if (result.status !== 200) throw result;
      const { status_code, results, plus_quota, status } = result.data;
      if (status === 'PROGRESS') {
        const timer = setTimeout(() => {
          getResult(taskId, modelType);
          clearTimeout(timer);
        }, 1000);
        return;
      }
      if (status_code === 200) {
        dispatch({
          type: 'global/setQuota',
          payload: result.data,
        });
        if (plus_quota <= 0 && modelType === 'plus') {
          Modal.warning({
            title: 'Your Plus Quota is not enough.',
            content: (
              <>
                Visit our <a href="/pricing">Pricing</a> page to recharge.
              </>
            ),
            okText: 'Learn More',
            closable: true,
            onOk: () => {
              window.location.href = '/pricing';
            },
          });
        }
        inputChange({ target: { value: results } });
      } else {
        throw result;
      }
    } catch (error) {
      console.log(error);
    }

    setOrcLoading(false);
  };

  const inputChange = (e) => {
    dispatch({
      type: 'global/setInputValue',
      payload: e.target.value,
    });
  };

  const showLoginPopup = (value = false) => {
    dispatch({
      type: 'global/setShowLogin',
      payload: value,
    });
  };

  const handleChange = (value, key) => {
    dispatch({
      type: 'global/set' + key,
      payload: value,
    });
  };

  const beforeUpload = () => {
    return false;
  };

  return (
    <section className={styles['upload-container']}>
      <div className={styles['file-type-selector']}>
        <div>
          <label>Model</label>
          <Select
            className={styles.select}
            value={model}
            onChange={(e) => handleChange(e, 'Model')}
            options={models.map((item) => {
              item.disabled = item.value === 'plus' && quotaData.plus_quota <= 0;
              return item;
            })}
            dropdownRender={(originNode) => {
              return (
                <div className={styles.dropdownRender}>
                  <div>{originNode}</div>
                  <Tag
                    className={styles.tag}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push('/pricing');
                    }}
                    color="#f50"
                  >
                    recharge
                  </Tag>
                </div>
              );
            }}
          />
        </div>
        <div>
          <label>Text Language</label>
          <Select
            className={styles.select}
            value={language}
            onChange={(e) => handleChange(e, 'Language')}
            options={languages.map((item, index) => {
              item.disabled = !(index <= 1 || model === 'plus');
              return item;
            })}
          />
        </div>
        <div>
          <label>File Type</label>
          <Select
            className={styles.select}
            value={fileType}
            onChange={(e) => handleChange(e, 'FileType')}
            options={fileTypeItems.map((item) => {
              if (item.value === 'page') {
                item.disabled = model === 'pro';
              }
              return item;
            })}
          />
        </div>
      </div>
      <Spin tip="Loading" spinning={orcLoading} className={styles['upload-area-wrap']}>
        <div className={styles.inputBox}>
          <div className={styles['upload-area']}>
            <Dragger
              beforeUpload={beforeUpload}
              showUploadList={false}
              onChange={fileChange}
              accept=".png, .jpg, .jpeg, .webp"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or copy image into the page for recognition.</p>
            </Dragger>
          </div>
          {img && <img src={img} className={styles.img} alt="" />}
        </div>
      </Spin>
    </section>
  );
};
