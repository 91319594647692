import { useState } from 'react';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { payAsYouGo, subscriptions } from './config';

const Buy = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.global.userId);

  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  const jumpBuy = (item) => {
    if (!userId) {
      return dispatch({
        type: 'global/setShowLogin',
        payload: true,
      });
    }
    const url = item.url + '?checkout[custom][user_id]=' + userId;
    window.open(url, '_blank');
  };

  return (
    <div className={styles.price}>
      <Header />

      <div className={`${styles['container']} ${styles['container2']}`}>
        <div className={styles['header']}>
          <h1 className={styles['title']}>Plus Model</h1>

          <h2 className={styles['subtitle']}>Quota & Subscription Options</h2>
        </div>

        <div className={styles['tab-container']}>
          {['Pay as You Go', 'Subscriptions'].map((item, index) => {
            return (
              <div
                onClick={() => setTabActiveIndex(index)}
                key={item}
                className={`${styles['tab']} ${tabActiveIndex === index && styles['active']}`}
              >
                {item}
              </div>
            );
          })}
        </div>

        {tabActiveIndex === 0 && (
          <>
            <h3 className={styles['section-title']}>Pay-as-You-Go Quota Packs</h3>

            <div className={styles['plans-container']}>
              {payAsYouGo.map((item) => {
                return (
                  <div key={item.price} className={styles['plan-card']}>
                    {item.best && <div className={styles['featured-badge']}>Best Value</div>}
                    <h4 className={styles['plan-title']}>{item.title}</h4>

                    {item.save && <div className={styles['savings-tag']}>SAVE {item.save}</div>}

                    <p className={styles['plan-title']}>{item.price}</p>
                    <p className={styles['price-period']}></p>

                    <div className={styles['feature-item']}>
                      <span className={styles['check-icon']}>✓</span>

                      <span className={styles['feature-text']}>Plus Quota:</span>

                      <span className={styles['feature-value']}>{item.plusQuota.toLocaleString()}</span>
                    </div>

                    <div className={styles['feature-item']}>
                      <span className={styles['check-icon']}>✓</span>

                      <span className={styles['feature-text']}>Valid for:</span>

                      <span className={styles['feature-value']}>{item.validfor}</span>
                    </div>
                    <div className={styles['feature-item']}>
                      <span className={styles['check-icon']}>✓</span>

                      <span className={styles['feature-text']}>Standard Support</span>
                    </div>

                    <p className={styles['value-prop']}>{item.info}</p>

                    <a href="#purchase-basic" onClick={() => jumpBuy(item)} className={styles['plan-button']}>
                      Purchase Now
                    </a>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {tabActiveIndex === 1 && (
          <>
            <h3 className={styles['section-title']}>Subscription Plans</h3>

            <div className={styles['plans-container']}>
              {subscriptions.map((item) => {
                return (
                  <div key={item.price} className={styles['plan-card']}>
                    {item.best && <div className={styles['featured-badge']}>Best Value</div>}
                    <h4 className={styles['plan-title']}>{item.title}</h4>

                    {item.save && <div className={styles['savings-tag']}>SAVE {item.save}</div>}

                    <p className={styles['plan-price']}>{item.price}</p>

                    <p className={styles['price-period']}>{item.subtitle}</p>

                    <div className={styles['feature-item']}>
                      <span className={styles['check-icon']}>✓</span>

                      <span className={styles['feature-text']}>Plus Quota:</span>

                      <span className={styles['feature-value']}>{item.plusQuota.toLocaleString()}</span>
                    </div>

                    <div className={styles['feature-item']}>
                      <span className={styles['check-icon']}>✓</span>

                      <span className={styles['feature-text']}>Priority Support</span>
                    </div>

                    <div className={styles['feature-item']}>
                      <span className={styles['check-icon']}>✓</span>

                      <span className={styles['feature-text']}>Cancel Anytime</span>
                    </div>

                    <p className={styles['value-prop']}>Best for individual professionals</p>

                    <a href="#subscribe-monthly" onClick={() => jumpBuy(item)} className={styles['plan-button']}>
                      Subscribe Now
                    </a>
                  </div>
                );
              })}
            </div>
          </>
        )}

        <div className={styles['footer']}>
          <p>
            注：支持<b>微信、支付宝</b>使用人民币直接付款。
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Buy;
