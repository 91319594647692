import { createSlice } from '@reduxjs/toolkit';
import { models, languages, fileTypeItems } from '../../pages/Index/config';

const initQuota = {
  monthly_quota: 0,
  plus_quota: 0,
  pro_quota: 0,
  total_quota: 0,
};

export const globalReducer = createSlice({
  name: 'global',
  initialState: {
    showLogin: false,
    showUpdatePassword: false,
    userId: null,
    quota: initQuota,
    model: models[0].value,
    language: languages[1].value,
    fileType: fileTypeItems[1].value,
    inputValue: '',
    taskId: '',
    callId: ''
  },
  reducers: {
    logout: (state) => {
      state.quota = initQuota;
      state.userId = null;
      localStorage.clear('session_id');
    },
    setFileType: (state, action) => {
      state.fileType = action.payload;
    },
    setQuota: (state, action) => {
      state.quota = action.payload;
      if(action.payload.plus_quota <= 0 && state.fileType === fileTypeItems[0].value){
        state.fileType = fileTypeItems[1].value;
      }
      if(action.payload.plus_quota <= 0 && state.model === models[1].value){
        state.model = models[0].value;
      }
      if(action.payload.plus_quota <= 0 && ![languages[0].value, languages[1].value].includes(state.language)){
        state.language = languages[0].value;
      }
    },
    setShowLogin: (state, action) => {
      state.showLogin = action.payload;
    },
    setShowUpdatePassword: (state, action) => {
      state.showUpdatePassword = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setModel: (state, action) => {
      state.model = action.payload;
      if (action.payload === 'pro') {
        state.language = languages[1].value;
      }
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setInputValue: (state, action) => {
      state.inputValue = action.payload;
    },
    setTaskId: (state, action) => {
      state.taskId = action.payload;
    },
    setCallId: (state, action) => {
      state.callId = action.payload;
    },
  },
});

// 为每个 case reducer 函数生成 Action creators
export const { reducers } = globalReducer.actions;

export default globalReducer.reducer;
