import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import aiteImg from '../../assets/images/aite.png';
import mimaImg from '../../assets/images/mima.png';
import userImg from '../../assets/images/user.png';
import cancelImg from '../../assets/images/cancel.png';
import yanzhengmaƒImg from '../../assets/images/yanzhengma.png';
import * as R from 'ramda';
import { sendCode, register, login, googleLoginApi } from '../../services';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import { message, Button } from 'antd';
const COUNT_DOWN_S = 120;
const COUNT_DOWN_START_MS_KEY = 'count_down_start_ms';

export const Login = () => {
  const dispatch = useDispatch();
  const showLogin = useSelector((state) => state.global.showLogin);
  const tabs = [
    {
      v: 'login',
      l: 'Login',
    },
    {
      v: 'sign_up',
      l: 'Sign Up',
    },
  ];
  const [tabActive, setTabActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sendText, setSendText] = useState('send');
  const [form, setform] = useState({
    email: '',
    password: '',
    name: '',
    verification_code: '',
    agreement: true,
  });

  useEffect(() => {
    const historyCountDown = localStorage.getItem(COUNT_DOWN_START_MS_KEY);
    if (historyCountDown) {
      const historyCountDownDiff = parseInt((new Date().getTime() - historyCountDown) / 1000);
      if (historyCountDownDiff < COUNT_DOWN_S) {
        countDown(COUNT_DOWN_S - historyCountDownDiff);
      } else {
        localStorage.clear(COUNT_DOWN_START_MS_KEY);
      }
    }
  }, []);

  const changeInput = (value, key) => {
    setform((oldV) => {
      return R.assocPath([key], value, oldV);
    });
  };

  const send = async () => {
    try {
      const { email, password, name } = form;
      if (!(vEmail() && password && name)) {
        return message.error('Please enter the correct name, email address and password');
      } else if (sendText !== 'send') {
        return false;
      }
      await sendCode({ email, password, name });
      countDown();
      message.success('Send successfully');
      localStorage.setItem(COUNT_DOWN_START_MS_KEY, new Date().getTime());
    } catch (error) {
      message.error(error.message || 'Failed to send, please try again');
    }
  };

  const countDown = (s = COUNT_DOWN_S) => {
    let count_down = s;
    const timer = setInterval(function () {
      count_down -= 1;
      if (count_down > 0) {
        setSendText(count_down + 's');
      } else {
        setSendText('send');
        clearInterval(timer);
      }
    }, 1000);
  };

  const submit = async () => {
    if (tabActive === 0) {
      loginFunc();
    } else {
      registerFunc();
    }
  };

  const showLoginPopup = (value = false) => {
    dispatch({
      type: 'global/setShowLogin',
      payload: value,
    });
  };

  const forgotPassword = () => {
    showLoginPopup(false);
    dispatch({
      type: 'global/setShowUpdatePassword',
      payload: true,
    });
  };

  const loginFunc = async () => {
    try {
      const { email, password, agreement } = form;
      if (!(vEmail() && password)) {
        return message.error('Please enter the correct name and email address');
      } else if (!agreement) {
        return message.error('Please read and confirm the User Agreement');
      }
      setLoading(true);
      const res = await login({ email, password });
      message.success(res.data.message);
      localStorage.setItem('session_id', res.data.user.session_id);
      showLoginPopup(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const registerFunc = async () => {
    try {
      const { email, password, name, verification_code, agreement } = form;
      if (!(vEmail() && password && name && verification_code)) {
        return message.error('Please enter the correct name, email address, password and verification code');
      } else if (!agreement) {
        return message.error('Please read and confirm the User Agreement');
      }
      setLoading(true);
      const res = await register({ email, password, name, verification_code });
      message.success(res.data.message);
      setTabActive(0);
    } catch (error) {}
    setLoading(false);
  };

  const vEmail = () => {
    return /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(form.email);
  };

  const googleLogin = async (credentialResponse) => {
    try {
      const res = await googleLoginApi({ token: credentialResponse.credential });
      message.success(res.data.message);
      localStorage.setItem('login_type', 'google');
      localStorage.setItem('session_id', res.data.user.session_id);
      showLoginPopup(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  if (!showLogin) {
    return null;
  }

  return (
    <div className={styles.loginBox} onClick={() => showLoginPopup(false)}>
      <div className={styles.login} onClick={(e) => e.stopPropagation()}>
        <div className={styles.tab}>
          {tabs.map((tab, index) => {
            return (
              <div
                className={tabActive === index ? styles.tabActive : ''}
                onClick={() => setTabActive(index)}
                key={tab.v}
              >
                {tab.l}
              </div>
            );
          })}
        </div>
        <div className={styles.inputs}>
          {tabActive === 1 && (
            <div className={styles.inputBox}>
              <div>
                <img src={userImg} alt="" />
              </div>
              <input
                value={form.name}
                onInput={(e) => changeInput(e.target.value, 'name')}
                type="input"
                placeholder="Name"
              />
            </div>
          )}
          <div className={styles.inputBox}>
            <div>
              <img src={aiteImg} alt="" />
            </div>
            <input
              value={form.email}
              onInput={(e) => changeInput(e.target.value, 'email')}
              type="input"
              placeholder="Email"
            />
          </div>
          <div className={styles.inputBox}>
            <div>
              <img src={mimaImg} alt="" />
            </div>
            <input
              value={form.password}
              onInput={(e) => changeInput(e.target.value, 'password')}
              type="password"
              placeholder="Password"
            />
          </div>
          {tabActive !== 0 && (
            <div className={styles.inputBox}>
              <div>
                <img src={yanzhengmaƒImg} alt="" />
              </div>
              <input
                value={form.verification_code}
                onInput={(e) => changeInput(e.target.value, 'verification_code')}
                type="input"
                placeholder="Verification code"
              />
              <div className={styles.send} onClick={() => send()}>
                {sendText}
              </div>
            </div>
          )}
        </div>
        <Button loading={loading} className={styles.button} onClick={() => submit()}>
          {tabs[tabActive].l}
        </Button>
        <div className={styles.googleLoginButton}>
          <GoogleLogin
            width="350px"
            onSuccess={(credentialResponse) => googleLogin(credentialResponse)}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </div>
        <label className={styles.agreement}>
          <input
            type="checkbox"
            checked={form.agreement}
            onChange={(e) => changeInput(e.target.checked, 'agreement')}
          />
          <div>
            <a
              className={styles.a}
              target="_blank"
              href="https://www.breezedeus.com/article/p2t-agreement"
              rel="noreferrer"
            >
              I accept the Terms of Service and Privacy Policy
            </a>
          </div>
        </label>
        <div className={styles.forgot} onClick={forgotPassword}>
          Forgot your password?
        </div>
        <img onClick={() => showLoginPopup(false)} className={styles.cancel} src={cancelImg} alt="" />
      </div>
    </div>
  );
};
