import { useEffect } from 'react';
import styles from './index.module.scss';
import { Header } from '../../components/Header';
import { OcrInput } from './components/OcrInput';
import { OcrOutput } from './components/OcrOutput';
import { Footer } from '../../components/Footer';

const Index = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch {}
  }, []);

  return (
    <div className={styles.indexBox}>
      <Header />

      <main className={`${styles['container']} ${styles['container2']}`}>
        <OcrInput />

        <OcrOutput />
        <div className={styles.adsbygoogle}>
          <ins
            className="adsbygoogle"
            style={{
              display: 'inline-block',
              width: '1200px',
              height: '200px',
            }}
            data-ad-client="ca-pub-8337601681919371"
            data-ad-slot="1275307237"
          ></ins>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Index;
