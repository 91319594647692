import { quota } from '../../services/index';
import { languages } from '../../pages/Index/config';
import store from '../index';

export const getQuota = async () => {
  try {
    const session_id = localStorage.getItem('session_id') || '';
    if (session_id) {
      const res = await quota({ session_id });
      const { plus_quota } = res.data;
      store.dispatch({
        type: 'global/setQuota',
        payload: res.data,
      });
      store.dispatch({
        type: 'global/setUserId',
        payload: res.data.user.id,
      });
      // 优先使用plus
      store.dispatch({
        type: 'global/setModel',
        payload: plus_quota > 0 ? 'plus' : 'pro',
      });
      store.dispatch({
        type: 'global/setLanguage',
        payload: languages[1].value,
      });
    }
  } catch {}
};
