export const payAsYouGo = [
  {
    title: '3-Day Pack',
    price: '$1.49',
    plusQuota: 300000,
    url: 'https://ocr.lemonsqueezy.com/buy/dbc8444a-2fd9-42fe-b6e3-4b2ee4d8f8ed',
    validfor: '3 Days',
    info: 'Perfect for quick projects and testing',
  },
  {
    title: '14-Day Pack',
    price: '$2.99',
    plusQuota: 1400000,
    url: 'https://ocr.lemonsqueezy.com/buy/034f7ae2-a464-41f6-830c-1b59360653b2',
    validfor: '14 Days',
    info: 'Perfect for short-term projects',
  },
  {
    title: '30-Day Pack',
    price: '$4.99',
    plusQuota: 3000000,
    url: 'https://ocr.lemonsqueezy.com/buy/58ee1706-a827-4dc7-9e96-afc69737cc0d',
    validfor: '30 Days',
    info: 'Perfect for long-term projects',
    save: '16%',
    best: true,
  },
];

export const subscriptions = [
  {
    title: 'Monthly Plan',
    price: '$3.99',
    subtitle: 'per month',
    plusQuota: 3000000,
    url: 'https://ocr.lemonsqueezy.com/buy/aef969c3-0e78-4ea7-bf4b-b798d43810df',
  },
  {
    title: 'Quarterly Plan',
    price: '$9.99',
    subtitle: 'per quarter',
    plusQuota: 10000000,
    url: 'https://ocr.lemonsqueezy.com/buy/8f8156b7-582e-4df6-ad84-dc6dafd514d9',
    save: '16%',
    best: true,
  },
];
