import styles from './index.module.scss';

export const Footer = () => {
  return (
    <footer>
      <div className={`${styles['container']} ${styles['footer-content']}`}>
        <span>Powered by</span>
        <a href="https://github.com/breezedeus/Pix2Text">Pix2Text (P2T)</a>
        <span>and</span>
        <a href="https://www.breezedeus.com/">Breezedeus</a>
      </div>
    </footer>
  );
};
